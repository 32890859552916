import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import LogRocket from 'logrocket'
import { moment, Utils } from '@pbt/pbt-ui-components'
import {
  KioskBackgroundCustom,
  KioskBackgroundDefault,
} from '@pbt/pbt-ui-components/src/icons'

import {
  getCurrentBusiness,
  getCurrentBusinessTimeZone,
} from '../store/duck/businesses'
import { useFullWindowDimensions } from '../utils/useFullWindowDimensions'
import MainKiosk from './MainKiosk'
import AppointmentConfirmationWrapperScreen from './screens/appointment-confirmation/AppointmentConfirmationWrapperScreen'
import ClientWrapperScreen from './screens/client/ClientWrapperScreen'
import LocationSelectorScreen from './screens/LocationSelectorScreen'
import MembershipWrapperScreen from './screens/membership/MembershipWrapperScreen'
import PaymentWrapperScreen from './screens/payments/PaymentWrapperScreen'

const useStyles = makeStyles(
  (theme) => ({
    background: {
      position: 'fixed',
      zIndex: 0,
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100%',
    },
    root: {
      minHeight: 'var(--app-height)',
    },
    rootWithPaddings: {
      padding: theme.spacing(5, 2, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1, 0),
      },
    },
  }),
  { name: 'Main' },
)

const Main = () => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const businessId = currentBusiness?.id
  const currentBusinessTimeZone = useSelector(getCurrentBusinessTimeZone)
  const location = useLocation()
  const isLocationSelector = location.pathname === '/location-selector'
  const hasBusiness = Boolean(currentBusiness)
  const { customColorsEnabled, buttonsColor, backgroundColor } =
    currentBusiness || {}
  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  const logRocketKey = Utils.isPlayEnvironment()
    ? 'w2huxx/kiosk-master'
    : Utils.isProduction()
    ? 'w2huxx/kiosk-prod'
    : 'w2huxx/kiosk-staging'

  if (
    !Utils.isLocalEnvironment() ||
    process.env.REACT_APP_ACTIVATE_LOGROCKET_LOCALLY
  ) {
    LogRocket.init(logRocketKey, {
      mergeIframes: true,
    })
  }

  useEffect(() => {
    if (businessId) {
      LogRocket.identify(`Stand alone - BusinessId: ${businessId}`, {
        name: currentBusiness.name,
        currentBusiness,
        isPortalEmbed: false,
      })
    }
  }, [businessId])

  useEffect(() => {
    if (currentBusinessTimeZone) {
      moment.tz.setDefault(currentBusinessTimeZone)
    }
  }, [currentBusinessTimeZone])

  useFullWindowDimensions()

  const background =
    customColorsEnabled && backgroundColor ? (
      <KioskBackgroundCustom
        className={classes.background}
        fill={backgroundColor}
      />
    ) : (
      <KioskBackgroundDefault className={classes.background} />
    )

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classes.root, {
        [classes.rootWithPaddings]: !isLocationSelector,
      })}
      direction="column"
      wrap="nowrap"
    >
      {hasBusiness && !isLocationSelector ? background : null}
      <Routes>
        <Route element={<PaymentWrapperScreen />} path="payment/*" />
        <Route element={<MembershipWrapperScreen />} path="membership/*" />
        <Route
          element={<AppointmentConfirmationWrapperScreen />}
          path="appointment-confirmation/*"
        />
        <Route element={<ClientWrapperScreen />} path="client/*" />
        <Route element={<LocationSelectorScreen />} path="location-selector" />
        <Route element={<MainKiosk />} path="/*" />
      </Routes>
    </Grid>
  )
}

export default Main
