import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { without } from 'ramda'

import {
  Invoice,
  InvoiceLineItemSoap,
} from '../../../../types/entities/finance'
import EstimateGroupHeader from './EstimateGroupHeader'
import EstimateTableMobile from './EstimateTableMobile'
import EstimateTableRow from './EstimateTableRow'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      padding: theme.spacing(1, 1),
      borderRight: theme.constants.tabBorder,
    },
    headerCell: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.4rem',
      borderTop: theme.constants.tabBorder,
      height: 25,
    },
    bodyHeaderCell: {
      paddingLeft: theme.spacing(3),
    },
  }),
  { name: 'EstimateTable' },
)

const getExpandedGroupName = (group?: string, soap?: InvoiceLineItemSoap) =>
  `${soap?.id || null}-${group}`

interface EstimateTableProps {
  invoice: Invoice
}

const EstimateTable = ({ invoice }: EstimateTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const groups = invoice?.groups || []

  const [expandedGroups, setExpandedGroups] = useState<string[]>([])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const onExpandChange = (group?: string, soap?: InvoiceLineItemSoap) => {
    const name = getExpandedGroupName(group, soap)
    if (expandedGroups.includes(name)) {
      setExpandedGroups(without([name], expandedGroups))
    } else {
      setExpandedGroups(expandedGroups.concat(name))
    }
  }

  if (isMobile) {
    return <EstimateTableMobile invoice={invoice} />
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          />
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:QUANTITY')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:DISCOUNT')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:TAX')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:ITEM_TOTAL')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groups.map((group) => (
          <React.Fragment key={group.soap?.date || 'otc'}>
            <TableRow>
              <TableCell
                className={classNames(
                  classes.tableCell,
                  classes.bodyHeaderCell,
                )}
              >
                <EstimateGroupHeader group={group} invoice={invoice} />
              </TableCell>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell} />
            </TableRow>
            {group.groupedItems.map((item, index) => (
              <EstimateTableRow
                invoice={invoice}
                isExpanded={expandedGroups.includes(
                  getExpandedGroupName(item.groupName, group.soap),
                )}
                item={item}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                soap={group.soap}
                onExpandChange={onExpandChange}
              />
            ))}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  )
}

EstimateTable.propTypes = {
  invoice: PropTypes.object,
}

export default EstimateTable
