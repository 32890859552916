import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '../../api'
import { PaymentProcedureType } from '../../constants/financeConstants'
import { Invoice } from '../../types/entities/finance'
import { type RootState } from '..'
import { updateCurrentBusiness } from './businesses'
import { updateCurrentClient } from './clients'

export const FETCH_PAYMENT_LINK_DATA = 'finance/FETCH_PAYMENT_LINK_DATA'
export const FETCH_PAYMENT_LINK_DATA_SUCCESS =
  'finance/FETCH_PAYMENT_LINK_DATA_SUCCESS'
export const FETCH_PAYMENT_LINK_DATA_FAILURE =
  'finance/FETCH_PAYMENT_LINK_DATA_FAILURE'

export const UPDATE_BILLING_ADDRESS = 'finance/UPDATE_BILLING_ADDRESS'

export const UPDATE_PAYMENT_AMOUNT = 'finance/UPDATE_PAYMENT_AMOUNT'

export const UPDATE_HAS_AGREED_TO_PAY = 'finance/UPDATE_HAS_AGREED_TO_PAY'

export const CREATE_PAYMENT = 'finance/CREATE_PAYMENT'
export const CREATE_PAYMENT_SUCCESS = 'finance/CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE = 'finance/CREATE_PAYMENT_FAILURE'

export const CREATE_STRIPE_SECRET = 'finance/CREATE_STRIPE_SECRET'
export const CREATE_STRIPE_SECRET_SUCCESS =
  'finance/CREATE_STRIPE_SECRET_SUCCESS'
export const CREATE_STRIPE_SECRET_FAILURE =
  'finance/CREATE_STRIPE_SECRET_FAILURE'

export const EMAIL_PAYMENT = 'finance/EMAIL_PAYMENT'
export const EMAIL_PAYMENT_SUCCESS = 'finance/EMAIL_PAYMENT_SUCCESS'
export const EMAIL_PAYMENT_FAILURE = 'finance/EMAIL_PAYMENT_FAILURE'

type BillingAddress = any

export type CreatePaymentData = {
  amount: string
  billingAddress: BillingAddress
  cryptogram: string
}

export const fetchPaymentLinkData = (token: string) => ({
  type: FETCH_PAYMENT_LINK_DATA,
  token,
})
export const fetchPaymentLinkDataSuccess = (data: any) => ({
  type: FETCH_PAYMENT_LINK_DATA_SUCCESS,
  data,
})
export const fetchPaymentLinkDataFailure = (error: ApiError) => ({
  type: FETCH_PAYMENT_LINK_DATA_FAILURE,
  error,
})

export const updateBillingAddress = (address: BillingAddress) => ({
  type: UPDATE_BILLING_ADDRESS,
  address,
})

export const updatePaymentAmount = (paymentAmount: number) => ({
  type: UPDATE_PAYMENT_AMOUNT,
  paymentAmount,
})

export const updateHasAgreedToPay = (hasAgreedToPay: boolean) => ({
  type: UPDATE_HAS_AGREED_TO_PAY,
  hasAgreedToPay,
})

export const createPayment = (token: string, data: CreatePaymentData) => ({
  type: CREATE_PAYMENT,
  token,
  data,
})
export const createPaymentSuccess = () => ({ type: CREATE_PAYMENT_SUCCESS })
export const createPaymentFailure = (error: ApiError) => ({
  type: CREATE_PAYMENT_FAILURE,
  error,
})

export const createStripeSecret = (token: string, data: CreatePaymentData) => ({
  type: CREATE_STRIPE_SECRET,
  token,
  data,
})
export const createStripeSecretSuccess = (secret: string) => ({
  type: CREATE_STRIPE_SECRET_SUCCESS,
  secret,
})
export const createStripeSecretFailure = (error: ApiError) => ({
  type: CREATE_STRIPE_SECRET_FAILURE,
  error,
})

export const emailPayment = (token: string, email: string) => ({
  type: EMAIL_PAYMENT,
  token,
  email,
})
export const emailPaymentSuccess = () => ({ type: EMAIL_PAYMENT_SUCCESS })
export const emailPaymentFailure = (error: ApiError) => ({
  type: EMAIL_PAYMENT_FAILURE,
  error,
})

type FinanceState = {
  balance: null
  billingAddress: null
  error: ApiError | null
  formData: { type: keyof typeof PaymentProcedureType } | null
  goProcessor: null
  hasAgreedToPay: boolean
  invoice: Invoice | null
  isExpired: boolean
  isFetchingLinkData: boolean
  isLoading: boolean
  isPaid: boolean
  paymentAmount: number | null
  stripeSecret: string | null
}

export const INITIAL_STATE: FinanceState = {
  hasAgreedToPay: false,
  balance: null,
  invoice: null,
  error: null,
  isExpired: false,
  isPaid: false,
  isLoading: false,
  isFetchingLinkData: false,
  billingAddress: null,
  formData: null,
  paymentAmount: null,
  goProcessor: null,
  stripeSecret: null,
}

export const financeReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case FETCH_PAYMENT_LINK_DATA:
      return { ...state, isLoading: true, isFetchingLinkData: true }
    case FETCH_PAYMENT_LINK_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetchingLinkData: false,
        error: null,
        ...action.data,
      }
    case FETCH_PAYMENT_LINK_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isFetchingLinkData: false,
      }
    case UPDATE_BILLING_ADDRESS:
      return { ...state, billingAddress: action.address }
    case UPDATE_PAYMENT_AMOUNT:
      return { ...state, paymentAmount: action.paymentAmount }
    case UPDATE_HAS_AGREED_TO_PAY:
      return { ...state, hasAgreedToPay: action.hasAgreedToPay }
    case CREATE_PAYMENT:
    case CREATE_STRIPE_SECRET:
      return { ...state, isLoading: true }
    case CREATE_PAYMENT_SUCCESS:
      return { ...state, isLoading: false, isPaid: true, error: null }
    case CREATE_STRIPE_SECRET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stripeSecret: action.secret,
        error: null,
      }
    case CREATE_PAYMENT_FAILURE:
      return { ...state, isLoading: false, error: action.error }
    case EMAIL_PAYMENT:
      return { ...state, isLoading: true }
    case EMAIL_PAYMENT_SUCCESS:
      return { ...state, isLoading: false }
    case EMAIL_PAYMENT_FAILURE:
    case CREATE_STRIPE_SECRET_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    default:
      return state
  }
}

export const getFinance = (state: RootState): FinanceState => state.finance
export const getInvoice = (state: RootState) => getFinance(state).invoice
export const getIsMultiInvoicePayment = (state: RootState) =>
  (getFinance(state).invoice?.invoices?.length ?? 0) > 0
export const getPaymentIsExpired = (state: RootState) =>
  getFinance(state).isExpired
export const getPaymentIsPaid = (state: RootState) => getFinance(state).isPaid
export const getFinanceIsLoading = (state: RootState) =>
  getFinance(state).isLoading
export const getFinanceIsFetchingLinkData = (state: RootState) =>
  getFinance(state).isFetchingLinkData
export const getFinanceError = (state: RootState) => getFinance(state).error
export const getFinanceBillingAddress = (state: RootState) =>
  getFinance(state).billingAddress
export const getFinanceFormData = (state: RootState) =>
  getFinance(state).formData
export const getIsAuthorize = (state: RootState) =>
  getFinance(state).formData?.type === PaymentProcedureType.AUTH
export const getFinanceBalance = (state: RootState) => getFinance(state).balance
export const getFinancePaymentAmount = (state: RootState) =>
  getFinance(state).paymentAmount
export const getFinanceHasAgreedToPay = (state: RootState) =>
  getFinance(state).hasAgreedToPay
export const getGoProcessor = (state: RootState) =>
  getFinance(state).goProcessor
export const getStripeSecret = (state: RootState) =>
  getFinance(state).stripeSecret

export function* sagaFetchPaymentLinkData({
  token,
}: ReturnType<typeof fetchPaymentLinkData>) {
  try {
    const { business, client, ...rest } = yield call(
      API.fetchPaymentLinkData,
      token,
    )
    if (business) {
      yield put(updateCurrentBusiness(business))
    }
    if (client) {
      yield put(updateCurrentClient(client))
    }
    yield put(fetchPaymentLinkDataSuccess(rest))
  } catch (error) {
    yield put(fetchPaymentLinkDataFailure(error as ApiError))
  }
}

export function* sagaCreatePayment({
  token,
  data,
}: ReturnType<typeof createPayment>) {
  try {
    yield call(API.createPayment, token, data)
    yield put(createPaymentSuccess())
  } catch (error) {
    yield put(createPaymentFailure(error as ApiError))
  }
}

export function* sagaCreateStripeSecret({
  token,
  data,
}: ReturnType<typeof createPayment>) {
  try {
    const result: { data: any } = yield call(API.createPayment, token, data)
    yield put(createStripeSecretSuccess(result?.data?.clientSecret))
  } catch (error) {
    yield put(createStripeSecretFailure(error as ApiError))
  }
}

export function* sagaEmailPayment({
  token,
  email,
}: ReturnType<typeof emailPayment>) {
  try {
    yield call(API.emailPayment, token, email)
    yield put(emailPaymentSuccess())
  } catch (error) {
    yield put(emailPaymentFailure(error as ApiError))
  }
}

function* watchFetchPaymentLinkData() {
  yield takeLatest(FETCH_PAYMENT_LINK_DATA, sagaFetchPaymentLinkData)
}

function* watchCreatePayment() {
  yield takeLatest(CREATE_PAYMENT, sagaCreatePayment)
}

function* watchEmailPayment() {
  yield takeLatest(EMAIL_PAYMENT, sagaEmailPayment)
}

function* watchCreateStripeSecret() {
  yield takeLatest(CREATE_STRIPE_SECRET, sagaCreateStripeSecret)
}

export function* financeSaga() {
  yield all([
    watchFetchPaymentLinkData(),
    watchCreatePayment(),
    watchEmailPayment(),
    watchCreateStripeSecret(),
  ])
}
