import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KibField } from '@chewy/kib-fields-new-react'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ErrorTooltip,
  LanguageUtils,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import Features from '../../../constants/features'
import { MembershipSignUpFlow } from '../../../constants/wellnessPlansConstants'
import { getCurrentBusiness } from '../../../store/duck/businesses'
import {
  clearEmailVerifyData,
  editClient,
  getClientEmailVerifyData,
  getClientsIsLoading,
  getCurrentClient,
  verifyClientEmailForBoop,
} from '../../../store/duck/clients'
import { getFeatureToggle } from '../../../store/duck/constants'
import {
  getMembershipActiveSignUpFlow,
  getMembershipIsFreeSignUp,
} from '../../../store/duck/wellnessPlans'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import KioskScreen from '../KioskScreen'
import useHandleFreeSignUp from './useHandleFreeSignUp'

import '@chewy/kib-fields-new-styles/dist/styles/kib-field.css'

const useStyles = makeStyles(
  (theme) => ({
    textField: {
      maxWidth: 382,
      marginTop: theme.spacing(2),
    },
    title: {
      textAlign: 'left',
      paddingLeft: theme.spacing(4),
    },
    content: {
      padding: theme.spacing(2, 4),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  }),
  { name: 'MembershipEmailVerificationScreen' },
)

const MembershipEmailVerificationScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Membership'])

  const client = useSelector(getCurrentClient)
  const clientsIsLoading = useSelector(getClientsIsLoading)
  const activeSignUpFlow = useSelector(getMembershipActiveSignUpFlow)
  const emailVerifyData = useSelector(getClientEmailVerifyData)
  const isFreeSignUp = useSelector(getMembershipIsFreeSignUp)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(Features.BOOP_DISABLEMENT),
  )
  const isIpoM2bBraintreeSignupEnabled = useSelector(
    getFeatureToggle(Features.IPO_M2B_BRAINTREE_SIGNUP),
  )
  const currentBusiness = useSelector(getCurrentBusiness)
  const isBusinessOmniChannel = currentBusiness?.omniChannel
  const shouldUseBraintree =
    isIpoM2bBraintreeSignupEnabled && isBusinessOmniChannel

  const { handler: handleFreeSignUp, isLoading: signUpIsLoading } =
    useHandleFreeSignUp()

  const clientName = LanguageUtils.capitalize(client?.firstName || 'client')
  const isLoading = clientsIsLoading || signUpIsLoading

  const onBeforeBack = () => {
    navigateWithQueryString({
      url: '/membership/sign-up',
      options: { replace: true },
    })
  }

  const {
    fields: { email },
    validate,
  } = useFields([
    {
      name: 'email',
      label: t('Common:EMAIL'),
      validators: ['email', 'required'],
      initialValue: client?.email || '',
    },
  ])

  useEffect(() => {
    if (emailVerifyData) {
      dispatch(clearEmailVerifyData())
    }
  }, [email.value])

  const onClientUpdated = () => {
    if (activeSignUpFlow === MembershipSignUpFlow.HYBRID) {
      const payload = {
        type: 'HANDLE_SEND_HYBRID_SIGN_UP_LINK',
        updatedEmail: email.value,
      }
      window?.parent?.postMessage(payload, '*')
    } else {
      handleFreeSignUp()
    }
  }

  const proceedWhenClientWillBeUpdated = useCallbackWhenLoaded(
    onClientUpdated,
    getClientsIsLoading,
  )

  const onVerified = () => {
    if (emailVerifyData?.valid) {
      if (email.value === client.email) {
        onClientUpdated()
      } else {
        const newClient = {
          ...client,
          email: email.value,
          patients: null,
          coparents: null,
        }
        proceedWhenClientWillBeUpdated()
        dispatch(editClient(newClient))
      }
    }
  }

  const proceedWhenEmailWillBeVerified = useCallbackWhenLoaded(
    onVerified,
    getClientsIsLoading,
  )

  const onProceed = () => {
    if (validate()) {
      proceedWhenEmailWillBeVerified()
      dispatch(
        verifyClientEmailForBoop(client.id, email.value, isBusinessOmniChannel),
      )
    }
  }

  const emailVerificationLabel = isBoopDisablementEnabled
    ? t('Membership:THIS_EMAIL_IS_USED_TO_IDENTIFY_CLIENT_ACCOUNT', {
        clientName,
      })
    : t('Membership:THIS_EMAIL_WILL_BE_USED_TO_IDENTIFY_CLIENT_IN_APP', {
        clientName,
      })

  return (
    <KioskScreen
      classes={{ content: classes.content, title: classes.title }}
      justifyContent="flex-start"
      proceedButtonDisabled={isLoading}
      proceedButtonLabel={
        isFreeSignUp ? t('Common:SIGN_UP') : t('Common:NEXT_ACTION')
      }
      proceedButtonLoading={isLoading}
      title={t('Common:CONFIRM_CLIENTS_EMAIL', { clientName })}
      onBeforeBack={onBeforeBack}
      onProceed={onProceed}
    >
      <Grid container item direction="column">
        <Typography>{emailVerificationLabel}</Typography>
        <ErrorTooltip
          message={
            Boolean(emailVerifyData.message) && t('Membership:DUPLICATE_EMAIL')
          }
          open={emailVerifyData.valid === false}
        >
          {shouldUseBraintree ? (
            <KibField
              className={classes.textField}
              invalid={!email.valid}
              label={`${email.label}*`}
              value={email.value}
              onChange={(event) => {
                email.setValue(event.target.value)
              }}
            />
          ) : (
            <PuiTextField
              autoFocus
              fullWidth
              className={classes.textField}
              disabled={isLoading}
              field={email}
              inputProps={{ maxLength: 100 }}
              label={`${email.label}*`}
              type="email"
            />
          )}
        </ErrorTooltip>
      </Grid>
    </KioskScreen>
  )
}

export default MembershipEmailVerificationScreen
