import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import {
  AlertIconType,
  NumberUtils,
  PuiAlert,
  Shadow,
} from '@pbt/pbt-ui-components'

import { getCurrentClient } from '../../../store/duck/clients'
import {
  createPayment,
  createStripeSecret,
  getFinanceBalance,
  getFinanceBillingAddress,
  getFinanceError,
  getFinanceFormData,
  getFinanceIsLoading,
  getFinancePaymentAmount,
  getGoProcessor,
  getInvoice,
  getIsAuthorize,
  getStripeSecret,
} from '../../../store/duck/finance'
import { useNavigateWithQueryString } from '../../../utils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import PaymentMethodScreen from '../common/PaymentMethodScreen'
import ConfirmPaymentPopper from './ConfirmPaymentPopper'
import PaymentDetailsScreen from './PaymentDetailsScreen'

const InvoicePaymentMethodScreen = () => {
  const location = useLocation()
  const { token } = parse(location.search)
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Error'])

  const formData = useSelector(getFinanceFormData) || {}
  const invoice = useSelector(getInvoice)
  const billingAddress = useSelector(getFinanceBillingAddress)
  const amount = useSelector(getFinancePaymentAmount)
  const balance = useSelector(getFinanceBalance)
  const isAuthorize = useSelector(getIsAuthorize)
  const goProcessor = useSelector(getGoProcessor)
  const stripeSecret = useSelector(getStripeSecret)
  const client = useSelector(getCurrentClient)
  const error = useSelector(getFinanceError)

  const isEstimate = invoice && invoice.type === 'Estimate'

  const rootRef = useRef()

  const [isBusy, setIsBusy] = useState(false)
  const [cryptogram, setCryptogram] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmPopperOpen, setConfirmPopperOpen] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(true)
  const [isErrorAlertShown, setIsErrorAlertShown] = useState(false)

  const isStripePayment = goProcessor === 'STRIPE'
  const { totalWithServiceFee } = balance || {}

  const onPaymentSuccess = () => {
    if (isEstimate) {
      navigateWithQueryString({ url: '/payment/deposit-success' })
    } else {
      navigateWithQueryString({ url: '/payment/success' })
    }
  }

  const onPaymentCreated = () => {
    if (error) {
      setIsErrorAlertShown(true)
      return
    }
    if (!isStripePayment) {
      onPaymentSuccess()
    }
  }

  const callbackWhenCreated = useCallbackWhenLoaded(
    onPaymentCreated,
    getFinanceIsLoading,
  )

  const recordPayment = () => {
    callbackWhenCreated()
    if (isStripePayment) {
      dispatch(
        createStripeSecret(token, {
          amount,
          cryptogram: '',
          billingAddress,
        }),
      )
    } else {
      dispatch(
        createPayment(token, {
          amount,
          cryptogram,
          billingAddress,
        }),
      )
    }
  }

  useEffect(() => {
    setIsProceedDisabled(!cryptogram || Boolean(errorMessage))
  }, [cryptogram, errorMessage])

  const onProceed = () => {
    if (isEstimate) {
      recordPayment()
    } else {
      setConfirmPopperOpen(true)
    }
  }

  const onGenerateCryptoSuccess = (cryptogram) => {
    setErrorMessage('')
    setIsBusy(false)
    setCryptogram(cryptogram)
  }

  const onGenerateCrypto = () => {
    setErrorMessage('')
    setCryptogram('')
  }

  const onError = (error) => {
    setErrorMessage(error)
  }

  const amountToPay = invoice ? totalWithServiceFee : amount
  const actionText = isAuthorize
    ? t('Common:AUTHORIZE_ACTION')
    : t('Common:PAY_ACTION')

  return (
    <PaymentDetailsScreen
      errorMessage={errorMessage}
      proceedButtonDisabled={isProceedDisabled}
      proceedButtonLabel={`${actionText} ${NumberUtils.formatMoney(
        amountToPay,
      )}`}
      proceedButtonLoading={isBusy}
      ref={rootRef}
      onProceed={onProceed}
    >
      <PaymentMethodScreen
        billingAddress={billingAddress}
        client={client}
        cryptogram={cryptogram}
        formData={formData}
        isBusy={isBusy}
        isStripePayment={isStripePayment}
        setIsBusy={setIsBusy}
        setIsProceedDisabled={setIsProceedDisabled}
        stripeSecret={stripeSecret}
        onError={onError}
        onGenerateCrypto={onGenerateCrypto}
        onGenerateCryptoSuccess={onGenerateCryptoSuccess}
        onPaymentSuccess={onPaymentSuccess}
      />
      <ConfirmPaymentPopper
        amount={amountToPay}
        anchorEl={rootRef.current}
        open={confirmPopperOpen}
        onClose={() => setConfirmPopperOpen(false)}
        onProceed={recordPayment}
      />
      <Shadow open={confirmPopperOpen} />
      <PuiAlert
        cancelButtonText={t('Common:CANCEL_ACTION')}
        iconType={AlertIconType.WARN}
        message={t('Error:PAYMENT_FAILED_MESSAGE')}
        open={isErrorAlertShown}
        onClose={() => setIsErrorAlertShown(false)}
        onOk={() => setIsErrorAlertShown(false)}
      />
    </PaymentDetailsScreen>
  )
}

export default InvoicePaymentMethodScreen
