import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { NumberUtils } from '@pbt/pbt-ui-components'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    textAlign: 'right',
    fontSize: '1.4rem',
    padding: theme.spacing(0.5, 2),
    border: `1px solid ${theme.colors.grayGray3}`,
    borderStyle: 'none solid',
  },
  alignLeft: {
    textAlign: 'left',
    width: '160px',
  },
  alignCenter: {
    textAlign: 'center',
  },
  estimatePaymentTable: {
    tableLayout: 'auto',
    textAlign: 'right',
    maxWidth: '390px',
    border: 'none',
  },
  tableHeaderCell: {
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '500',
    fontSize: '1.2rem',
    color: theme.colors.grayGray2,
    padding: theme.spacing(1, 2),
  },
  tableHeaderTopBorder: {
    borderTopStyle: 'solid',
  },
  depositSection: {
    backgroundColor: theme.colors.grayGray4,
  },
  depositDueCell: {
    padding: theme.spacing(1, 2, 0.5),
    fontSize: '1.6rem',
    fontWeight: 'bold',
    borderStyle: 'solid none none',
    '&:first-child': {
      borderStyle: 'solid none none solid',
    },
    '&:last-child': {
      borderStyle: 'solid solid none none',
    },
  },
  depositMessageCell: {
    fontSize: '1.2rem',
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.colors.grayGray3}`,
    borderTop: 'none',
  },
  estimatedTotalCell: {
    borderTop: `1px solid ${theme.colors.grayGray3}`,
    padding: theme.spacing(1, 2),
    borderBottomStyle: 'none',
  },
}))

const EstimatePaymentDetails = ({ invoice, includeTopBorder = true }) => {
  const { t } = useTranslation('Common')

  const classes = useStyles()

  const { subtotal, totalDiscount, totalTax, dueToPay } = invoice || {}

  const isSubTotalRanged = NumberUtils.isAmountObj(subtotal)
  const isTotalDiscountRanged = NumberUtils.isAmountObj(totalDiscount)
  const isTotalTaxRanged = NumberUtils.isAmountObj(totalTax)
  const isDueToPayRanged = NumberUtils.isAmountObj(dueToPay)
  const isAnyAmountRanged =
    isSubTotalRanged ||
    isTotalDiscountRanged ||
    isTotalTaxRanged ||
    isDueToPayRanged

  const amountObjects = [
    {
      label: t('Common:SUBTOTAL'),
      isAmountRanged: NumberUtils.isAmountObj(invoice.subtotal),
      low: subtotal.low,
      high: subtotal.high,
      cellClassName: '',
      amount: subtotal,
    },
    {
      label: t('Common:DISCOUNT'),
      isAmountRanged: NumberUtils.isAmountObj(totalDiscount),
      low: totalDiscount.low,
      high: totalDiscount.high,
      cellClassName: '',
      amount: totalDiscount,
    },
    {
      label: t('Common:TAX'),
      isAmountRanged: NumberUtils.isAmountObj(totalTax),
      low: totalTax.low,
      high: totalTax.high,
      cellClassName: classes.taxRowCell,
      amount: totalTax,
    },
    {
      label: t('Common:ESTIMATED_TOTAL'),
      isAmountRanged: NumberUtils.isAmountObj(subtotal),
      low: subtotal.low,
      high: subtotal.high,
      amount: subtotal,
      cellClassName: classes.estimatedTotalCell,
    },
    {
      label: t('Common:DEPOSIT_DUE_TODAY'),
      isAmountRanged: NumberUtils.isAmountObj(dueToPay),
      low: dueToPay.low,
      high: dueToPay.high,
      amount: dueToPay,
      cellClassName: classNames(classes.depositSection, classes.depositDueCell),
    },
  ]

  function lowAndHighCells(value) {
    return (
      <>
        <td
          className={classNames(
            classes.tableCell,
            classes.lowCell,
            value.cellClassName,
          )}
        >
          {NumberUtils.formatMoney(value.low ?? value.amount)}
        </td>
        <td className={classNames(classes.tableCell, value.cellClassName)}>
          {NumberUtils.formatMoney(value.high ?? value.amount)}
        </td>
      </>
    )
  }

  const headerClasses = classNames(classes.tableCell, classes.tableHeaderCell, {
    [classes.tableHeaderTopBorder]: includeTopBorder,
  })

  return (
    <table className={classes.estimatePaymentTable}>
      <tr>
        <td className={headerClasses} />
        {isAnyAmountRanged ? (
          <>
            <th className={headerClasses}>{t('Common:LOW_TOTAL')}</th>
            <th className={headerClasses}>{t('Common:HIGH_TOTAL')}</th>
          </>
        ) : (
          <th className={headerClasses}> {t('Common:TOTAL')}</th>
        )}
      </tr>
      <tbody>
        {amountObjects.map((it) => (
          <tr key={it.label}>
            <td
              className={classNames(
                classes.tableCell,
                classes.alignLeft,
                it.cellClassName,
              )}
            >
              {it.label}
            </td>
            {it.isAmountRanged ? (
              lowAndHighCells(it)
            ) : (
              <td
                className={classNames(classes.tableCell, it.cellClassName, {
                  [classes.alignCenter]: isAnyAmountRanged,
                })}
                colSpan={2}
              >
                {NumberUtils.formatMoney(it.amount)}
              </td>
            )}
          </tr>
        ))}

        <tr>
          <td
            className={classNames(
              classes.depositSection,
              classes.depositMessageCell,
            )}
            colSpan={3}
          >
            {t('Common:DEPOSIT_TOTAL_MESSAGE')}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default EstimatePaymentDetails
