import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Link, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { parse } from 'query-string'
import { useInterval, Utils } from '@pbt/pbt-ui-components'

import Features from '../constants/features'
import Flows from '../constants/flows'
import { fetchBusiness, getCurrentBusiness } from '../store/duck/businesses'
import { fetchClientById, getCurrentClient } from '../store/duck/clients'
import {
  fetchConstants,
  getConstantsIsLoading,
  getFeatureToggle,
} from '../store/duck/constants'
import { addLastError, getKioskDisabled } from '../store/duck/errors'
import {
  getIsEmbedded,
  setEmbedded,
  updateCurrentFlow,
} from '../store/duck/flow'
import { getCurrentPatient, updateCurrentPatient } from '../store/duck/patients'
import {
  fetchAppointment,
  getCurrentAppointment,
} from '../store/duck/schedules'
import { getPortalUrl, useNavigateWithQueryString } from '../utils'
import FullscreenCircularProgress from './FullscreenCircularProgress'
import FullscreenLink from './FullscreenLink'
import KioskWrapperScreen from './screens/KioskWrapperScreen'
import InClinicWaitlistScreen from './screens/welcome/InClinicWaitlistScreen'

const useStyles = makeStyles(
  (theme) => ({
    linkLeft: {
      left: theme.spacing(2),
    },
    linkRight: {
      right: theme.spacing(2),
    },
    link: {
      cursor: 'pointer',
      position: 'fixed',
      bottom: theme.spacing(2),
      color: (props) =>
        props.buttonsColor ? theme.colors.profileText : theme.colors.tabLabel,
      fontSize: '1.4rem',
      textDecoration: 'none',
    },
  }),
  { name: 'MainKiosk' },
)

const MainKiosk = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const {
    businessId: businessIdParam,
    embed,
    eventId,
    clientId,
  } = parse(location.search)
  const isUserFeedback = location.pathname.indexOf('nps') > 0
  const isErrorScreen = location.pathname.indexOf('error') > 0
  const [hasProceededToCheckIn, setHasProceededToCheckIn] = useState(false)
  const currentBusiness = useSelector(getCurrentBusiness)
  const kioskDisabled = useSelector(getKioskDisabled)
  const appointment = useSelector(getCurrentAppointment)
  const client = useSelector(getCurrentClient)
  const patient = useSelector(getCurrentPatient)
  const isEmbedded = useSelector(getIsEmbedded)
  const constantsLoading = useSelector(getConstantsIsLoading)
  const isConsentFormEnabled = useSelector(
    getFeatureToggle(Features.DISPLAY_CONSENT_FORMS_ON_KIOSK),
  )

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { id } = currentBusiness || {}
  const businessId = businessIdParam || id
  const isFetchingDataForCheckIn =
    eventId &&
    clientId &&
    (!appointment || !client || !patient || constantsLoading)

  const proceedToCheckIn = () => {
    setHasProceededToCheckIn(true)
    const patient = Utils.findById(appointment.patient.id, client.patients)

    dispatch(updateCurrentPatient(patient))
    dispatch(updateCurrentFlow(Flows.CHECK_IN))

    if (isConsentFormEnabled) {
      navigateWithQueryString({ url: '/consent-forms' })
    } else {
      navigateWithQueryString({
        url: '/client-details',
        search: embed ? location.search : `${location.search}&embed=true`,
        ignoreParams: ['clientId', 'eventId'],
      })
    }
  }

  useEffect(() => {
    if (
      client &&
      appointment &&
      !constantsLoading &&
      eventId &&
      !hasProceededToCheckIn
    ) {
      proceedToCheckIn()
    }
  }, [client, appointment, constantsLoading, eventId, hasProceededToCheckIn])

  useEffect(() => {
    if (!businessId && !isErrorScreen) {
      dispatch(
        addLastError({
          data: 'Business not found. Did you enter the correct businessId?',
        }),
      )
    }
  }, [businessId, isErrorScreen])

  useEffect(() => {
    if (businessId && !kioskDisabled) {
      dispatch(fetchBusiness(businessId))
      dispatch(fetchConstants(businessId))
    }
  }, [businessId, kioskDisabled])

  useInterval(() => {
    // periodically pull business to have updated working hours and other info
    if (businessId) {
      dispatch(fetchBusiness(businessId))
    }
  }, 60000 * 5)

  useEffect(() => {
    dispatch(setEmbedded(embed))
  }, [embed])

  useEffect(() => {
    // if we got an eventId in URL - proceed to check in flow
    if (!eventId || !clientId || !currentBusiness) {
      return
    }

    dispatch(fetchAppointment(eventId))
    dispatch(fetchClientById(clientId, eventId))
  }, [currentBusiness, eventId, clientId])

  if (!businessId && !isUserFeedback && !isErrorScreen) {
    return null
  }

  const showSpinner =
    !isUserFeedback &&
    !isErrorScreen &&
    ((!currentBusiness && !kioskDisabled) || isFetchingDataForCheckIn)

  if (showSpinner) {
    return <FullscreenCircularProgress />
  }

  return (
    <>
      <Routes>
        <Route element={<InClinicWaitlistScreen />} path="waitlist/*" />
        <Route element={<KioskWrapperScreen />} path="/*" />
      </Routes>
      {!isEmbedded && !isMobile && (
        <>
          <Link
            className={classNames(classes.linkLeft, classes.link)}
            href={getPortalUrl('auth/lock')}
          >
            {t('Common:TEAM_MEMBER_UNLOCK')}
          </Link>
          <FullscreenLink
            className={classNames(classes.linkRight, classes.link)}
          />
        </>
      )}
    </>
  )
}

export default MainKiosk
