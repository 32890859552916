import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '2.4rem',
      marginBottom: '2.4rem',
    },
    text: {
      color: theme.colors.primaryText,
      fontWeight: 400,
      fontSize: '1.6rem',
    },
  }),
  { name: 'DepositPaymentSuccessScreen' },
)

const DepositPaymentSuccessScreen = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Payment'])

  const business = useSelector(getCurrentBusiness)

  return (
    <KioskScreen alignItems="center" justifyContent="center">
      <Grid container item alignItems="center" direction="column">
        <Typography className={classes.title}>
          {t('Payment:THANK_YOU_FOR_YOUR_DEPOSIT')}
        </Typography>
        <Typography className={classes.text}>
          {t('Payment:HAVE_QUESTIONS')}
        </Typography>
        <Typography className={classes.text}>
          {t('Payment:CALL_US', { phone: business?.phone })}
        </Typography>
      </Grid>
    </KioskScreen>
  )
}

export default DepositPaymentSuccessScreen
