import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import LogRocket from 'logrocket'
import { parse } from 'query-string'

import {
  fetchBusiness,
  getCurrentBusiness,
} from '../../../store/duck/businesses'
import {
  getCurrentClient,
  updateCurrentClient,
} from '../../../store/duck/clients'
import { fetchConstants } from '../../../store/duck/constants'
import {
  fetchMembershipSignUpDataForToken,
  fetchWellnessPlansVersion,
  setMembershipIsPortalEmbed,
  updateMembershipBillingAddresses,
} from '../../../store/duck/wellnessPlans'
import FullscreenCircularProgress from '../../FullscreenCircularProgress'
import KioskWrapper from '../../KioskWrapper'
import membershipRoutes from './membershipRoutes'

const MembershipWrapperScreen = () => {
  const { search } = useLocation()
  const { businessId: businessIdParam, portalEmbed, token } = parse(search)
  const dispatch = useDispatch()
  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getCurrentClient)
  const [portalLogRocketSessionURL, setPortalLogRocketSessionURL] = useState('')

  const { id } = currentBusiness || {}
  const businessId = businessIdParam || id
  const isPortalEmbed = Boolean(portalEmbed)

  const handleParentMessage = (event) => {
    if (
      !event.origin
        .toString()
        // Regex matches any subdomains of rhapsody.vet
        .match(/^https:\/\/([a-zA-Z0-9-]+\.)?rhapsody\.vet\/.*$/)
    ) {
      return
    }

    if (event.data.type === 'PORTAL_LOGROCKET_SESSION_URL') {
      setPortalLogRocketSessionURL(event.data.portalLogRocketSessionURL)
    }

    if (event.data.type === 'PROVIDE_CLIENT') {
      dispatch(updateMembershipBillingAddresses(event.data.billingAddresses))
      dispatch(updateCurrentClient(event.data.client))
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleParentMessage)
    return () => {
      window.removeEventListener('message', handleParentMessage)
    }
  }, [])

  useEffect(() => {
    if (isPortalEmbed && currentBusiness) {
      LogRocket.identify(
        `Portal iFrame - BusinessId: ${businessId ?? 'Unknown'}`,
        {
          name: currentBusiness.name,
          currentBusiness,
          isPortalEmbed,
          portalLogRocketSessionURL,
        },
      )
    }
  }, [isPortalEmbed, currentBusiness, portalLogRocketSessionURL])

  const rootUrl = token ? '/membership' : '/'

  useEffect(() => {
    if (businessId) {
      if (!isPortalEmbed) {
        dispatch(fetchBusiness(businessId))
      }
      dispatch(fetchConstants(businessId))
    }
  }, [businessId])

  useEffect(() => {
    if (token) {
      dispatch(fetchMembershipSignUpDataForToken(token))
    } else {
      dispatch(fetchWellnessPlansVersion(businessId))
    }
  }, [token])

  useEffect(() => {
    dispatch(setMembershipIsPortalEmbed(isPortalEmbed))
  }, [isPortalEmbed])

  const showSpinner = !currentBusiness

  if (showSpinner) {
    return <FullscreenCircularProgress />
  }

  return (
    <KioskWrapper
      businessLogoClickable={!token}
      hideBusinessInfo={isPortalEmbed}
      hideBusinessLogo={isPortalEmbed}
      redirectCondition={!client}
      rootUrl={rootUrl}
      routes={membershipRoutes}
    />
  )
}

export default MembershipWrapperScreen
