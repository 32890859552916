import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Stack, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Text } from '@pbt/pbt-ui-components'
import { isSigned } from '@pbt/pbt-ui-components/src/utils/consentFormUtils'

import {
  getDocumentsV2IsLoading,
  getDocumentsV2List,
} from '../../../store/duck/documentsV2'
import {
  checkInForAppointment,
  getCurrentAppointment,
} from '../../../store/duck/schedules'
import { Appointment } from '../../../types/entities/appointments'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../utils'
import KioskScreen from '../KioskScreen'

const StyledContainer = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(4)};
`
const StyledDoneIcon = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.uiSuccess};
  background-color: ${({ theme }) => theme.colors.shadesSuccess};
  padding: 0 ${({ theme }) => theme.spacing()};
`

export function ConsentFormThankYouScreen() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const documentsList = useSelector(getDocumentsV2List)
  const isLoading = useSelector(getDocumentsV2IsLoading)
  const appointment = useSelector(getCurrentAppointment) as Appointment | null

  return (
    <KioskScreen
      hideBackButton
      confirmButtonProps={{
        style: { background: `${theme.colors.brandPrimary}` },
      }}
      justifyContent="flex-start"
      proceedButtonDisabled={isLoading || !appointment}
      proceedButtonLabel="Finish"
      onProceed={() => {
        if (appointment) dispatch(checkInForAppointment(appointment.id, true))
        navigateWithQueryString({
          url: '/',
          ignoreParams: ['clientId', 'eventId'],
        })
      }}
    >
      <StyledContainer spacing={2}>
        {!isLoading ? (
          <>
            <Grid item xs={12}>
              <Text variant="h2">{`Thank you for completing your ${
                documentsList.length === 1 ? 'form' : 'forms'
              }`}</Text>
            </Grid>
            {documentsList
              .filter((form) => isSigned(form))
              .map((form) => (
                <Grid container item key={form.id}>
                  <Grid item xs={6}>
                    <Text>{form.name}</Text>
                  </Grid>
                  <Grid item>
                    <StyledDoneIcon>Done</StyledDoneIcon>
                  </Grid>
                </Grid>
              ))}
          </>
        ) : null}
      </StyledContainer>
    </KioskScreen>
  )
}
