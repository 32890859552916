import ErrorScreen from '../ErrorScreen'
import DepositPaymentSuccessScreen from './DepositPaymentSuccessScreen'
import EstimateScreen from './estimate/EstimateScreen'
import InvoiceScreen from './invoice/InvoiceScreen'
import InvoicePaymentMethodScreen from './InvoicePaymentMethodScreen'
import PayBalanceScreen from './PayBalanceScreen'
import PaymentAlreadyPaidScreen from './PaymentAlreadyPaidScreen'
import PaymentBillingAddressScreen from './PaymentBillingAddressScreen'
import PaymentExpiredScreen from './PaymentExpiredScreen'
import PaymentFinishedScreen from './PaymentFinishedScreen'
import PaymentRootScreen from './PaymentRootScreen'
import PaymentSuccessScreen from './PaymentSuccessScreen'

/* root path: /payment */

export default [
  { Component: InvoiceScreen, path: 'invoice', isRoot: true },
  { Component: EstimateScreen, path: 'estimate', isRoot: true },
  { Component: PaymentAlreadyPaidScreen, path: 'already-paid', isRoot: true },
  { Component: PaymentExpiredScreen, path: 'expired', isRoot: true },
  { Component: PayBalanceScreen, path: 'balance', isRoot: true },
  { Component: ErrorScreen, path: 'error', isRoot: true },

  { Component: PaymentFinishedScreen, path: 'finish' },
  { Component: PaymentBillingAddressScreen, path: 'billing-address' },
  { Component: InvoicePaymentMethodScreen, path: 'method' },
  { Component: PaymentSuccessScreen, path: 'success' },
  { Component: DepositPaymentSuccessScreen, path: 'deposit-success' },

  { Component: PaymentRootScreen, path: '/*', isRoot: true },
]
